import React, { useState } from "react";
import styled from "styled-components";

const NotificationPopover = () => {
  const [isVisible, setIsVisible] = useState(true);

  const closePopover = () => {
    setIsVisible(false);
  };

  if (!isVisible) return null;

  return (
    <Popover style={{ backgroundColor: "#0095da" }}>
      <div className="popover-content" data-aos="fade-in">
        <span className="close-icon" onClick={closePopover}>
          &times;
        </span>
        <div className="popover-body">
          <div className="image-section">
            <img
              src="/Flash for TV ads Website copy_page-0001.jpg" // Replace with your college image URL
              alt="College"
            />
          </div>
          <div className="form-section">
            <h2>College Inquiry Form</h2>
            <form>
              <label>
                Name:
                <input type="text" placeholder="Your Name" required />
              </label>
              <label>
                Email:
                <input type="email" placeholder="Your Email" required />
              </label>
              <label>
                Message:
                <textarea
                  placeholder="Your Message"
                  rows="4"
                  required
                ></textarea>
              </label>
              <button type="submit" onClick={closePopover}>
                Submit
              </button>
            </form>
          </div>
        </div>
      </div>
    </Popover>
  );
};

const Popover = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 1rem;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  z-index: 1000;
  max-width: 800px;
  width: 90%;
  text-align: center;

  .popover-content {
    position: relative;
    display: flex;
    flex-direction: column;

    .close-icon {
      position: absolute;
      top: 10px;
      right: 15px;
      font-size: 1.5rem;
      color: #333;
      cursor: pointer;
      font-weight: bold;
    }

    .popover-body {
      display: flex;
      gap: 1rem;

      .image-section {
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 1rem;
        img {
          max-width: 100%;
          border-radius: 8px;
        }
      }

      .form-section {
        flex: 1;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 1rem;

        h2 {
          margin-bottom: 1rem;
          font-size: 1.5rem;
          color: #333;
        }

        form {
          display: flex;
          flex-direction: column;
          gap: 1rem;
          width: 100%;

          label {
            display: flex;
            flex-direction: column;
            text-align: left;
            font-size: 1rem;
            font-weight: 500;
            color: #555;

            input,
            textarea {
              margin-top: 0.5rem;
              padding: 0.5rem;
              border: 1px solid #ccc;
              border-radius: 5px;
              font-size: 1rem;
              width: 100%;
            }

            textarea {
              resize: none;
            }
          }

          button {
            padding: 0.7rem 1.2rem;
            font-size: 1rem;
            border: none;
            border-radius: 5px;
            cursor: pointer;
            background-color: #2085de;
            color: white;
            font-weight: bold;
            align-self: flex-end;
          }
        }
      }
    }
  }
`;

export default NotificationPopover;
