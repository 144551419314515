import React from "react";
import { Carousel } from "react-responsive-carousel";

// Import the carousel styles
import "../css/Banner.css";
import "react-responsive-carousel/lib/styles/carousel.min.css";

function Banner() {
  const images = [
    "Flash for TV ads Website copy_page-0001.jpg",
    "Flash for TV ads Website copy_page-0003.jpg",
    //   "Flash for TV ads Website copy_page-0004.jpg",
    //  "Flash for TV ads Website copy_page-0005.jpg",
    "Flash for TV ads Website copy_page-0006.jpg",
    "Flash for TV ads Website copy_page-0007.jpg",
    "Flash for TV ads Website copy_page-0008.jpg",
    // "Flash for TV ads Website copy_page-0009.jpg",
    // `Flash for TV ads Website copy_page-0002.jpg`,
  ];

  return (
    <div className="banner-div overlay">
      <Carousel
        autoPlay
        infiniteLoop
        showThumbs={false}
        showStatus={false}
        interval={1500}
      >
        {images.map((src, index) => (
          <div key={index}>
            <img className="banner" src={src} alt={`slide ${index + 1}`} />
          </div>
        ))}
      </Carousel>
    </div>
  );
}

export default Banner;
